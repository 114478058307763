import { getAuth } from "firebase/auth";
import fire from "../fire";
import Router from "next/router";

const db = fire.firestore();

const getOrgs = () => {
  try {
    return db
      .collection("Organisations")
      .where(`Admin.${getAuth().currentUser.uid}`, "==", true)
      .get()
      .then((snapshot) => {
        if (snapshot.size > 0) {
          console.log({ snapshot });
          return snapshot;
        } else {
          return { docs: [] };
        }
      });
  } catch (err) {
    return { docs: [] };
  }
};

const accountStatus = (org) => {
  try {
    const accountStatusRef = db.collection("AccountStatus").doc(org);
    return accountStatusRef.get().then((accountStatusDoc) => {
      if (
        accountStatusDoc &&
        accountStatusDoc.data() &&
        accountStatusDoc.data().funder
      ) {
        return true;
      } else {
        return false;
      }
    });
  } catch (err) {
    return false;
  }
};

const orgAccountData = (snapshot) => {
  let org;
  const ids = snapshot.docs.map((doc) => doc.id);
  try {
    if (ids.length === 0) {
      // Router.push('/user-org-signup')
      // throw 'No organisation'
    } else if (
      localStorage.getItem("ttsOrg") &&
      ids &&
      ids.includes(localStorage.getItem("ttsOrg"))
    ) {
      org = localStorage.getItem("ttsOrg");
    } else {
      [org] = ids;
    }
  } catch (err) {
    if (ids?.[0]) {
      [org] = ids;
    }
  }
  console.log({ ids });
  return accountStatus(org);
};

const routeToFunderDashboard = () =>
  getOrgs()
    .then((snapshot) => orgAccountData(snapshot))
    .then((result) => {
      if (result) {
        Router.push("/funder/list");
      }
    })
    .catch((err) => console.log({ err }));

export { routeToFunderDashboard };
